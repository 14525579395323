<template>
  <div class="yimiao">
    <div>
      <div>
        <van-cell-group>
          <van-field
            v-model="datafrom.CateName"
            required
            @click="cateIdShow = true"
            label="商品类别"
            readonly="readonly"
            placeholder="请选择商品类别"
          />
          <van-popup
            v-model="cateIdShow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="cateIdlist"
              value-key="Name"
              @cancel="cateIdShow = false"
              @confirm="oncateId"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.SoName"
            required
            @click="sortIdShow = true"
            label="商品分类"
            readonly="readonly"
            placeholder="请选择商品分类"
          />
          <van-popup
            v-model="sortIdShow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="sortIdlist"
              value-key="Name"
              @cancel="sortIdShow = false"
              @confirm="onsortId"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.PayModeName"
            required
            @click="PayModeShow = true"
            label="支付方式"
            readonly="readonly"
            placeholder="请选择支付方式"
          />
          <van-popup
            v-model="PayModeShow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="PayModelist"
              value-key="Name"
              @cancel="PayModeShow = false"
              @confirm="onPayMode"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Name"
            required
            label="名 称"
            placeholder="请输入商品名称"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Describe"
            label="描 述"
            type="textarea"
            placeholder="请输入商品描述"
          />
        </van-cell-group>
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">图片</span>
        </div>
        <van-uploader
          v-model="jkImageList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true"
        />
        <van-cell-group>
          <van-field
            v-model="datafrom.Amount"
            label="单 价"
            required
            placeholder="请输入商品单价"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.MUnit"
            label="单 位"
            required
            placeholder="请输入商品单位"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.SortNum"
            label="排 序"
            placeholder="请输入商品排序"
          />
        </van-cell-group>
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"
            >*</span
          >
          <span style="color: #646566; font-size: 14px">状态</span>
          <div style="margin-left: 17%; display: inline-block">
            <van-radio-group
              v-model="datafrom.Status"
              style="line-height: 20px"
              direction="horizontal"
            >
              <van-radio name="1">启用</van-radio>
              <van-radio name="2">禁用</van-radio>
            </van-radio-group>
          </div>
        </div>

        <van-cell-group>
          <van-field
            v-model="datafrom.Remark"
            label="备 注"
            type="textarea"
            placeholder="请输入备注"
          />
        </van-cell-group>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info" style="margin: 10px 0" @click="postfrom()"
          >保存</van-button
        >
        <!-- <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import {
  WxGetGoodsCateList,
  WxGetGoodsSortList,
  WxMySaveGoodsSort,
  WxGetMyGoods,
} from "@/api/RealInfo";
import axios from "axios";
Vue.use(Toast);
import { GetStaff, WxSaveStaff } from "@/api/RealInfo";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      // 商品图片
      jkImageList: [],
      NativeCantonCodedata: [],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        GoodsId: 0, //id、
        CateId: "",
        SortId: "",
        CateName: "",
        SoName: "",
        Name: "",
        Describe: "",
        Img: "",
        MUnit: "",
        Amount: "",
        SortNum: "",
        Status: "1",
        Remark: "",
        PayMode: "",
      },
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      recumenFend: false, //出生日期
      sortIdlist: [],
      cateIdlist: [],
      cateIdShow: false,
      sortIdShow: false,
      PayModeShow: false,
      PayModelist: [
        { Id: 1, Name: "现金" },
        { Id: 2, Name: "会员积分" },
        { Id: 3, Name: "党建积分" },
        { Id: 4, Name: "志愿者积分" },
      ],
    };
  },
  created() {
    // this.GetOrgansNullToken();
    console.log(this.$route.params);
    this.WxGetGoodsCateList();
    this.shopXq();
    this.WxGetGoodsSortList(this.$route.params.shopId);
  },
  methods: {
    // 商铺类别
    WxGetGoodsCateList: function () {
      WxGetGoodsCateList({ openID: getOpenId() }).then((res) => {
        this.cateIdlist = res.data.data;
      });
    },
    oncateId(val) {
      console.log(val);
      this.datafrom.CateId = val.CateId;
      this.datafrom.CateName = val.Name;
      this.cateIdShow = false;
      this.datafrom.SortId = "";
      this.datafrom.SoName = "";
      this.WxGetGoodsSortList(val.CateId);
    },
    // 商品类别
    WxGetGoodsSortList: function (row) {
      WxGetGoodsSortList({
        openID: getOpenId(),
        shopId: this.$route.params.shopId,
      }).then((res) => {
        this.sortIdlist = res.data.data;
      });
    },
    // 商品类别选择器
    onsortId(val) {
      this.datafrom.SortId = val.SortId;
      this.datafrom.SoName = val.Name;
      this.sortIdShow = false;
    },
    // 确定支付方式
    onPayMode(val) {
      this.datafrom.PayMode = val.Id;
      this.datafrom.PayModeName = val.Name;
      this.PayModeShow = false;
    },
    // 获取详情
    shopXq: function () {
      console.log(this.$route);
      if (this.$route.params.id == "1") {
        this.datafrom = {
          GoodsId: 0, //id、
          CateId: "",
          SortId: "",
          CateName: "",
          SoName: "",
          Name: "",
          Describe: "",
          Img: "",
          MUnit: "",
          Amount: "",
          SortNum: "",
          Status: "1",
          Remark: "",
        };
      } else {
        WxGetMyGoods({
          openID: getOpenId(),
          goodsId: this.$route.params.goodsId,
          shopId: this.$route.params.shopId,
        }).then((res) => {
          this.bangdingShow = true;
          if (res.data.code == 0) {
            console.log(res.data.data);
            this.datafrom = res.data.data;
            //重点监控
            // this.bjZuoBiao = res.data.data.Coordinate
            this.WxGetGoodsSortList(res.data.data.CateId);
            this.datafrom.SortId = res.data.data.SortId;
            this.datafrom.SoName = res.data.data.SortName;
            this.datafrom.PayMode = res.data.data.PayMode;
            this.datafrom.PayModeName = res.data.data.PayModeDesc;
            if (res.data.data.ImgUrl != "") {
              var jkImageLists = [res.data.data.ImgUrl];
              var arr = [];
              jkImageLists.forEach(function (imgUrl) {
                arr.push({ url: imgUrl });
              });
              this.jkImageList = arr;
            }

            this.datafrom.Status = res.data.data.Status + "";
            // this.datafrom.OrgCode = res.data.data.OrgCode;
            // this.datafrom.OrgName = res.data.data.OrgName;

            // this.datafrom.EType = res.data.data.EType;
            // this.datafrom.NationalityName = res.data.data.ShopTypeDesc;
          }
        });
      }
    },

    //上传商品图片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.data);
              that.datafrom.Img = res.data.picurl[0];
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商品图片
    jkDelete(file) {
      this.jkImageList = [];
      this.datafrom.Img = "";
    },

    // 提交
    postfrom: function () {
      console.log(this.$route.params);
      console.log(this.datafrom);

      if (this.datafrom.CateId == "") {
        Toast.fail("请选择商铺类型");
        return false;
      }

      if (this.datafrom.SortId == "") {
        Toast.fail("请选择商品分类");
        return false;
      }
      if (this.datafrom.PayModeName == "") {
        Toast.fail("请选择支付方式");
        return false;
      }
      if (this.datafrom.Name == "") {
        Toast.fail("请输入商品名称");
        return false;
      }

      if (this.datafrom.Img == "") {
        Toast.fail("请上传商品图片");
        return false;
      }
      if (this.datafrom.MUnit == "") {
        Toast.fail("请输入单位");
        return false;
      }
      if (this.datafrom.Amount == "") {
        Toast.fail("请输入单价");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      this.datafrom.ShopId = this.$route.params.shopId;
      // this.datafrom.OpenID = 'oh5yu5MZRYsqSdFQIXzJ4mnwRAPM';
      WxMySaveGoodsSort(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "保存成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "保存失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    gitocodelist() {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "ygList",
        query: { seId: this.$route.query.seId, ekind: this.$route.query.ekind },
      });
    },
    // 获取身份证
    IdNum(value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go(val) {
      var iden =
        this.datafrom.IdCard == ""
          ? this.datafrom.IdCard
          : this.datafrom.IdCard;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      // this.datafrom.NativeCantonCode = id;
      // console.log(this.datafrom.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      }
    },
    // 社区选择器
    onidListOrg(val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao(val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 返回时间
    ondatafromBackTime(val) {
      this.datafrom.BackTime = this.dateformat(val);
      this.datafromBackTime = false;
    },
    // 前进时间
    ondatafromOutTime(val) {
      this.datafrom.OutTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode(val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>